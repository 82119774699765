<template>
    <HeaderComponent /> 
    <MobileMenu />
    <BannerInitial />
    <GlobeComponent />
    <AreaOptions />
    <CustomizerOptions />
    <ElementIncluded />
    <StunningDemos />
    <ExclusiveFeature />
    <CourseShowcase />
    <!-- <GloballyTrusted /> -->
    <!-- <TopFeature /> -->
    <!-- <PeopleReview /> -->
    <FaqSection />
    <!-- <PurshareNow /> -->
    <FooterLanding />
    
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import BannerInitial from '@/components/BannerInitial.vue'
import AreaOptions from '@/components/AreaOptions.vue'
import CustomizerOptions from '@/components/CustomizerOptions.vue'
import ElementIncluded from '@/components/ElementIncluded.vue'
import StunningDemos from '@/components/StunningDemos.vue'
import ExclusiveFeature from '@/components/ExclusiveFeature.vue'
import CourseShowcase from '@/components/CourseShowcase.vue'
// import GloballyTrusted from '@/components/GloballyTrusted.vue'
// import TopFeature from '@/components/TopFeature.vue'
// import PeopleReview from '@/components/PeopleReview.vue'
import FaqSection from '@/components/FaqSection.vue'
// import PurshareNow from '@/components/PurshareNow.vue'
import FooterLanding from '@/components/FooterLanding.vue'
import GlobeComponent from '@/components/GlobeComponent.vue'
export default {
  name: 'BodyComponent',
  components: {
    HeaderComponent,
    MobileMenu,
    BannerInitial,
    AreaOptions,
    CustomizerOptions,
    ElementIncluded,
    StunningDemos,
    ExclusiveFeature,
    CourseShowcase,
    // GloballyTrusted,
    // TopFeature,
    // PeopleReview,
    FaqSection,
    // PurshareNow,
    FooterLanding,
    GlobeComponent
  }
}
</script>

<style scoped>
</style>