<template>
    <div class="copyright-area copyright-style-1 ptb--20 bg-color-darker">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <p class="rbt-link-hover text-center color-white-off">Histudy by <a href="https://themeforest.net/user/rbt-themes" class="color-white">Rainbow-Themes.</a> © All rights reserved</p>
                </div>
            </div>
        </div>
    </div>


    <div class="rbt-progress-parent">
        <svg class="rbt-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
</template>

<script>
export default {
  name: 'FooterLanding',
}
</script>

<style scoped>
</style>