<template>
     <div class="rbt-splash-coding-quality-area bg-color-white rbt-section-gapBottom mt-5">
        <div class="wrapper">
            <div class="container">
                <!-- <div class="row mb--60">
                    <div class="col-lg-12">
                        <div class="section-title text-center">
                            <span class="subtitle bg-secondary-opacity">All in One Template</span>
                            <h2 class="title">Why Choose Histudy.</h2>
                        </div>
                    </div>
                </div> -->
                <div class="row g-5">
                    <!-- Start Top Feature  -->
                    <div class="col-lg-4 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div class="top-features-box h-100 text-center bg-gradient-15">
                            <div class="inner">
                                <div class="content mb-5">
                                    <!-- <span class="pre-title text-uppercase">For Online Courses</span> -->
                                    <h4 class="title">La clave en nanomercados es la información de calidad y el análisis profundo</h4>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <img src="assets/images/splash/topfeature/01.png" alt="Image">
                                </div>
                                <!-- <div class="rbt-badge-group">
                                    <span class="rbt-badge">Individual instructor</span>
                                    <span class="rbt-badge">Multiple Instructors</span>
                                    <span class="rbt-badge">Marketplace</span>
                                    <span class="rbt-badge">Single Course</span>
                                    <span class="rbt-badge">Like Udemy</span>
                                    <span class="rbt-badge">& More...</span>
                                </div> -->
                            </div>
                            <div class="shape-image">
                                <img src="assets/images/splash/icons/sun-shadow-right.png" alt="Shape Images">
                            </div>
                        </div>
                    </div>
                    <!-- End Top Feature  -->

                    <!-- Start Top Feature  -->
                    <div class="col-lg-4 col-md-6 col-12" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                        <div class="top-features-box h-100 text-center bg-gradient-16">
                            <div class="inner">
                                <div class="content mb-5">
                                    <!-- <span class="pre-title text-uppercase">Diversificar en nano caps es esencial para mitigar riesgos en estos mercados volátiles</span> -->
                                    <h4 class="title">Diversificar en nano caps es esencial para mitigar riesgos en estos mercados volátiles</h4>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <img src="assets/images/splash/topfeature/02.png" alt="Image">
                                </div>
                                <!-- <div class="rbt-badge-group">
                                    <span class="rbt-badge">Higher education</span>
                                    <span class="rbt-badge">Universitys</span>
                                    <span class="rbt-badge">Kindergarden</span>
                                    <span class="rbt-badge">Pre School</span>
                                    <span class="rbt-badge">High School</span>
                                    <span class="rbt-badge">& More...</span>
                                </div> -->
                            </div>
                            <div class="shape-image">
                                <img src="assets/images/splash/icons/sun-shadow-right-2.png" alt="Shape Images">
                            </div>
                        </div>
                    </div>
                    <!-- End Top Feature  -->

                    <!-- Start Top Feature  -->
                    <div class="col-lg-4 col-md-6 col-12" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">
                        <div class="top-features-box h-100 text-center bg-gradient-17">
                            <div class="inner">
                                <div class="content mb-5">
                                    <!-- <span class="pre-title text-uppercase">FOR Profetional Coching </span> -->
                                    <h4 class="title">Poseer conocimientos privilegiados o realizar investigaciones exhaustivas puede marcar la diferencia entre una inversión exitosa.</h4>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <img src="assets/images/splash/topfeature/03.png" alt="Image">
                                </div>

                                <!-- <div class="rbt-badge-group">
                                    <span class="rbt-badge">Language Academy</span>
                                    <span class="rbt-badge">Gym Coaching</span>
                                    <span class="rbt-badge">Career Trenning </span>
                                    <span class="rbt-badge">Life Coaching</span>
                                    <span class="rbt-badge">& More...</span>
                                </div> -->

                            </div>
                            <div class="shape-image">
                                <img src="assets/images/splash/icons/sun-shadow-right-3.png" alt="Shape Images">
                            </div>
                        </div>
                    </div>
                    <!-- End Top Feature  -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sal from 'sal.js'
import 'sal.js/dist/sal.css'
export default {
    
  name: 'AreaOptions',
  mounted() {
    sal();
  }
}
</script>

<style scoped>
</style>