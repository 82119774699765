<template>
    <div class="rbt-pluginpresentation-area bg-color-white rbt-section-gapTop pt-0 mb-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <!-- <span class="subtitle bg-primary-opacity">CORE FEATURES</span> -->
                        <h2 class="title">Acceder a información exclusiva en nano caps es clave para maximizar rendimientos</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ExclusiveFeature',
}
</script>

<style scoped>
</style>