<template>
    <div class="rbt-inner-page-layout-area bg-color-white rbt-section-gap rbt-shape-bg-area top-circle-shape-top pb-2">
        <div class="wrapper position-relative overflow-hidden">
            <div class="rbt-splite-style">
                <div class="split-wrapper">
                    <div class="row g-0 align-items-center">
                        <div class="col-lg-12 col-xl-5 col-12">
                            <div class="swiper banner-splash-inner-layout-active rbt-arrow-between rbt-dot-bottom-center icon-bg-primary">
                                <div class="swiper-wrapper">

                                    <!-- Start Single Slider  -->
                                    <div class="swiper-slide" style="margin-left: 8rem;">
                                        <div class="single-slide">
                                            <div class="">
                                                <div class="thumbnail image-left-content">
                                                    <img src="assets/images/splash/innerlayout/course-layout-01.png" alt="split Images">
                                                </div>
                                                <div class="content text-center">
                                                    <h4 class="title">Course Layout One</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-xl-6 col-12">
                            <div class="split-inner">
                                <!-- <span class="rbt-badge-6 bg-primary-opacity">Course Showcase</span> -->
                                <h4 class="title">Invertir en nano caps requiere tolerancia al riesgo y un enfoque estratégico</h4>
                                <p class="description">Nanomercados están llenos de oportunidades para inversores que buscan diversificación en activos pequeños</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-0 align-items-center">
                        <div class="col-lg-12 col-xl-6 col-12">
                            <div class="split-inner">
                                <!-- <span class="rbt-badge-6 bg-primary-opacity">Course Showcase</span> -->
                                <h4 class="title">Criptomonedas en nanomercados pueden experimentar un crecimiento explosivo en poco tiempo</h4>
                                <p class="description">Nano caps son ideales para inversores que buscan aprovechar las fluctuaciones de mercado</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xl-5 col-12">
                            <div class="swiper banner-splash-inner-layout-active rbt-arrow-between rbt-dot-bottom-center icon-bg-primary">
                                <div class="swiper-wrapper">

                                    <!-- Start Single Slider  -->
                                    <div class="swiper-slide" style="margin-left: 8rem;">
                                        <div class="single-slide">
                                            <div class="">
                                                <div class="thumbnail image-left-content">
                                                    <img src="assets/images/splash/innerlayout/course-layout-01.png" alt="split Images">
                                                </div>
                                                <div class="content text-center">
                                                    <h4 class="title">Course Layout One</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                    <div class="row g-0 align-items-center">
                        <div class="col-lg-12 col-xl-5 col-12">
                            <div class="swiper banner-splash-inner-layout-active rbt-arrow-between rbt-dot-bottom-center icon-bg-primary">
                                <div class="swiper-wrapper">

                                    <!-- Start Single Slider  -->
                                    <div class="swiper-slide" style="margin-left: 8rem;">
                                        <div class="single-slide">
                                            <div class="">
                                                <div class="thumbnail image-left-content">
                                                    <img src="assets/images/splash/innerlayout/course-layout-01.png" alt="split Images">
                                                </div>
                                                <div class="content text-center">
                                                    <h4 class="title">Course Layout One</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-xl-6 col-12">
                            <div class="split-inner">
                                <!-- <span class="rbt-badge-6 bg-primary-opacity">Course Showcase</span> -->
                                <h4 class="title">Criptomonedas en nanomercados pueden experimentar un crecimiento explosivo en poco tiempo</h4>
                                <p class="description">Nano caps son ideales para inversores que buscan aprovechar las fluctuaciones de mercado</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="top-circle-shape"></div>
        </div>
        <div class="rbt-shape-bg">
            <img src="assets/images/splash/bg/left-right-line-small.svg" alt="Shape Images">
        </div>
    </div>
</template>

<script>
export default {
  name: 'CourseShowcase',
}
</script>

<style scoped>
</style>