<template>
    <div class="rbt-splash-slider d-flex align-items-center">
        <div class="wrapper">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-xl-6 order-2 order-xl-1">
                        <div class="inner">
                            <div class="banner-top">
                                <div class="banner-badge-top">
                                    <div class="icon">
                                        <div class="rating">
                                            <a href="#"><i class="fa fa-star"></i></a>
                                            <a href="#"><i class="fa fa-star"></i></a>
                                            <a href="#"><i class="fa fa-star"></i></a>
                                            <a href="#"><i class="fa fa-star"></i></a>
                                            <a href="#"><i class="fa fa-star"></i></a>
                                        </div>
                                    </div>
                                    <span class="subtitle">12500+ Trust Customer</span>
                                </div>

                                <div class="banner-badge-top">
                                    <div class="icon">
                                        <img src="assets/images/icons/elite.svg" alt="Icons Images">
                                    </div>
                                    <span class="subtitle">Envato Elite Author</span>
                                </div>
                            </div>
                            <h1 class="title">NANOMERCADOS UNA VENTAJA DEL NUEVO SIGLO</h1>
                            <p class="description">Las nano caps de ser desconocidas a ser gigantes en el espacio cripto</p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-6 order-1 order-xl-2">
                        <div class="video-popup-wrapper">
                            <img class="w-100 rbt-radius" src="assets/images/splash/banner-group-image.png" alt="Video Images">
                            <a class="rbt-btn rounded-player-2 popup-video position-to-top with-animation d-none" href="https://www.youtube.com/watch?v=nA1Aqp0sPQo">
                                <span class="play-icon"></span>
                            </a>
                            <div class="banner-group-shape">
                                <div class="shape-image scene shape-4">
                                    <span data-depth="2">
                                        <img src="assets/images/splash/icons/shape-4.png" alt="Shape Images">
                                    </span>
                                </div>
                                <div class="shape-image scene shape-5">
                                    <span data-depth="-2">
                                        <img src="assets/images/splash/icons/shape-5.png" alt="Shape Images">
                                    </span>
                                </div>
                                <div class="shape-image scene shape-6">
                                    <span data-depth="5">
                                        <img src="assets/images/splash/icons/shape-6.png" alt="Shape Images">
                                    </span>
                                </div>
                                <div class="shape-image scene shape-7">
                                    <span data-depth="-3">
                                        <img src="assets/images/splash/icons/shape-7.png" alt="Shape Images">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="row">
                        <div class="splash-service-main position-relative">
                            <div class="service-wrapper service-white">
                                <div class="row g-0">
                                    <div class="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 service__style--column">
                                        <div class="service service__style--1">
                                            <div class="icon">
                                                <img src="assets/images/icons/icons-01.png" alt="Icon Images">
                                            </div>
                                            <div class="content">
                                                <h4 class="title mb-5">Los nanomercados presentan oportunidades únicas debido a su alta volatilidad</h4>
                                                <p>Nanomercados permiten a los inversores acceder a activos con menor cobertura mediática, pero alto potencial.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 service__style--column">
                                        <div class="service service__style--1">
                                            <div class="icon">
                                                <img src="assets/images/icons/icons-02.png" alt="Icon Images">
                                            </div>
                                            <div class="content">
                                                <h4 class="title mb-5">Invertir en nano caps puede generar rendimientos elevados</h4>
                                                <p>La inversión temprana en nano caps puede resultar en </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 service__style--column">
                                        <div class="service service__style--1">
                                            <div class="icon">
                                                <img src="assets/images/icons/icons-03.png" alt="Icon Images">
                                            </div>
                                            <div class="content">
                                                <h4 class="title mb-5">Las ineficiencias de los nanomercados permiten aprovechar discrepancias de precios</h4>
                                                <p>Nano caps son vehículos ideales para estrategias de inversión</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 service__style--column">
                                        <div class="service service__style--1">
                                            <div class="icon">
                                                <img src="assets/images/icons/icons-04.png" alt="Icon Images">
                                            </div>
                                            <div class="content">
                                                <h4 class="title mb-5">Las criptomonedas emergentes en nanomercados tienen un potencial de crecimiento exponencial</h4>
                                                <p>Nanomercados ofrecen una combinación única de riesgo y recompensa que atrae a inversores audaces
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="shape-wrapper">
            <div class="shape-image shape-1">
                <img src="assets/images/splash/icons/shape-1.png" alt="Shape Images">
            </div>
            <div class="shape-image shape-2">
                <img src="assets/images/splash/icons/shape-2.png" alt="Shape Images">
            </div>
            <div class="shape-image shape-3">
                <img src="assets/images/splash/icons/shape-3.png" alt="Shape Images">
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BannerInitial',
}
</script>

<style scoped>
</style>