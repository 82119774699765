<template>
    <div class="rbt-advance-tab-area splash-layout-presentation pb--40 bg-color-white">
        <div class="wrapper">
            <div class="container">
                <div class="row mb--60">
                    <div class="col-lg-12">
                        <div class="section-title text-center">
                            <!-- <span class="subtitle bg-secondary-opacity">You Can Customize Everything
                            </span> -->
                            <h2 class="title">Tiempo real de los NANOCAPS donde invertimos</h2>
                            <!-- <p class="description mt--20">An all-in-one template with powerful features.
                            </p> -->
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="splash-layout-presentation-box">
                            <div class="row g-5">
                                <div class=" col-12 mt_md--30 mt_sm--30 order-2 order-lg-1">

                                    <div class="section-title text-start mb--60">
                                        <span class="subtitle bg-primary-opacity">All in One for Education
                                        </span>
                                        <h4 class="title">Layout Ready, Build Everything.</h4>
                                    </div>

                                    <div class="advance-tab-button advance-tab-button-1">
                                        <ul class="nav nav-tabs splash-nav-tabs tab-button-list" id="splashLayoutTab" role="tablist">
                                            <li class="nav-item active-nav" role="presentation">
                                                <a href="#" class="nav-link tab-button active" id="layouttab1-tab" data-bs-toggle="tab" data-bs-target="#layouttab1" role="tab" aria-controls="layouttab1" aria-selected="false">
                                                    <div class="tab">
                                                        <h4 class="title"><img src="assets/images/splash/icons/online-course.png" alt="advance-tab-image"> Nuevas cripto emergentes en las que estamos invirtiendo</h4>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="nav-item" role="presentation">
                                                <a href="#" class="nav-link tab-button" id="layouttab2-tab" data-bs-toggle="tab" data-bs-target="#layouttab2" role="tab" aria-controls="layouttab2" aria-selected="true">
                                                    <div class="tab">
                                                        <h4 class="title"><img src="assets/images/splash/icons/header.png" alt="advance-tab-image"> Criptos donde ya hemos sacado beneficios</h4>
                                                    </div>
                                                </a>
                                            </li>

                                            <!-- <li class="nav-item" role="presentation">
                                                <a href="#" class="nav-link tab-button" id="layouttab3-tab" data-bs-toggle="tab" data-bs-target="#layouttab3" role="tab" aria-controls="layouttab3" aria-selected="false">
                                                    <div class="tab">
                                                        <h4 class="title"><img src="assets/images/splash/icons/curve.png" alt="advance-tab-image"> Complete Design Toolkit.</h4>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="nav-item" role="presentation">
                                                <a href="#" class="nav-link tab-button" id="layouttab4-tab" data-bs-toggle="tab" data-bs-target="#layouttab4" role="tab" aria-controls="layouttab4" aria-selected="false">
                                                    <div class="tab">
                                                        <h4 class="title"><img src="assets/images/splash/icons/web-programming.png" alt="advance-tab-image"> Infinitely Flexible</h4>
                                                    </div>
                                                </a>
                                            </li> -->
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-12 order-1 order-lg-2">
                                    <div class="tab-content">
                                        <CryptoTable2/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <ul class="plan-offer-list d-block d-sm-flex justify-content-center text-center">
                            <li><i class="feather-check "></i>Nanomercados permiten acceder a criptomonedas emergentes con alto potencial de crecimiento</li>
                            <li><i class="feather-check"></i>Nano caps ofrecen oportunidades de inversión únicas en mercados menos cubiertos</li>
                            <li><i class="feather-check"></i>La alta volatilidad de nano caps puede traducirse en ganancias rápidas para inversores atentos</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CryptoTable2 from '@/components/CryptoTable2.vue'
export default {
  name: 'CustomizerOptions',
  components: {
    CryptoTable2
  }
}
</script>

<style scoped>
</style>