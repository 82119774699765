<template>
  <div class="container mx-auto px-4 sm:px-8">
    <div class="py-8">
      <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table class="min-w-full leading-normal">
            <thead>
              <tr>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Logo
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Nombre
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Precio
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Market Cap
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  VWAP (24Hr)
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Supply
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Volumen (24h)
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Cambio (24h)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="coin in coins" :key="coin.id" :class="{ 'bg-green-200': coin.priceIncreased, 'bg-red-200': coin.priceDecreased }">
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div class="flex">
                    <div class="flex-shrink-0 w-10 h-10">
                      <img class="w-full h-full rounded-full" :src="coin.icon" :alt="coin.name" />
                    </div>
                  </div>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">{{ coin.name }} ({{ coin.symbol }})</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ formatNumber(coin.price) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ formatNumber(coin.marketCap) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ formatNumber(coin.vwap24Hr) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">{{ formatNumber(coin.supply) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ formatNumber(coin.volume) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p :class="{'text-green-600': coin.changePercent24Hr > 0, 'text-red-600': coin.changePercent24Hr < 0}">
                    {{ formatNumber(coin.changePercent24Hr, 2) }}%
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import axios from 'axios'

const coins = ref([])
let socket

const formatNumber = (value, decimals = 2) => {
  // Verificar si value es undefined o null
  if (value === undefined || value === null) {
    return '0';
  }

  // Convertir a número y verificar si es NaN
  const num = Number(parseFloat(value));
  if (isNaN(num)) {
    return '0';
  }

  // Formatear el número
  return num.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
}

onMounted(async () => {
  // Conectar al websocket de coincap
  socket = new WebSocket('wss://ws.coincap.io/prices?assets=ALL')

  socket.onmessage = (msg) => {
    const data = JSON.parse(msg.data)
    
    // Actualizar precio de cada moneda
    coins.value = coins.value.map(coin => {
      if (data[coin.id]) {
        coin.priceIncreased = parseFloat(data[coin.id]) > parseFloat(coin.price)
        coin.priceDecreased = parseFloat(data[coin.id]) < parseFloat(coin.price)
        coin.price = data[coin.id]
        
        if (coin.priceIncreased || coin.priceDecreased) {
          setTimeout(() => {
            coin.priceIncreased = false
            coin.priceDecreased = false
          }, 1000)
        }
      }
      return coin
    });
  }
  
  // Obtener datos iniciales
  try {
    const startRank = 361
    const endRank = 370
    const limit = endRank - startRank + 1
    const offset = startRank - 1

    const response = await axios.get(`https://api.coincap.io/v2/assets?limit=${limit}&offset=${offset}&order=rank`)
    
    coins.value = response.data.data.map(coin => ({
      id: coin.id,
      icon: `https://assets.coincap.io/assets/icons/${coin.symbol.toLowerCase()}@2x.png`,
      name: coin.name,
      symbol: coin.symbol,
      price: coin.priceUsd,
      marketCap: coin.marketCapUsd,
      vwap24Hr: coin.vwap24Hr,
      supply: coin.supply,
      volume: coin.volumeUsd24Hr,
      changePercent24Hr: coin.changePercent24Hr
    }))
  } catch (error) {
    console.error('Error fetching coin data:', error)
  }
})

onUnmounted(() => {
  // Desconectar del websocket al desmontar
  if (socket) {
    socket.close()
  }
})
</script>

<style scoped>
.bg-green-200 {
  transition: background-color 1s;
}

.bg-red-200 {
  transition: background-color 1s;
}
</style>