<template>
    <div class="rbt-elements-presentation-area overflow-hidden bg-color-white rbt-section-gapBottom pt--80 pb-2">
        <div class="wrapper">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <!-- <span class="subtitle bg-primary-opacity">You don't need any external plugins.</span> -->
                        <h2 class="title">Nanomercados suelen estar subvalorados <br> Ofreciendo un potencial de revalorización significativo</h2>
                    </div>
                </div>
            </div>

            <div class="scroll-animation-all-wrapper">
                <div class="scroll-animation-wrapper mt--30">
                    <div class="scroll-animation scroll-right-left">
                        <!-- Start Single Testimonial  -->
                        <div class="single-column-100">
                            <div class="rbt-categori-list">
                                <a href="#"><i class="feather-credit-card"></i> Accordion</a>
                                <a href="#"><i class="feather-folder"></i> Advance Tab</a>
                                <a href="#"><i class="feather-heart"></i> Brand</a>
                                <a href="#"><i class="feather-link-2"></i> Button</a>
                                <a href="#"><i class="feather-award"></i> Badge</a>
                                <a href="#"><i class="feather-layers"></i> Card</a>
                                <a href="#"><i class="feather-log-in"></i> Call To Action</a>
                                <a href="#"><i class="feather-hash"></i> Counter</a>
                                <a href="#"><i class="feather-grid"></i> Categories</a>
                                <a href="#"><i class="feather-mail"></i> Newsletter</a>
                                <a href="#"><i class="feather-users"></i> Team</a>
                                <a href="#"><i class="feather-twitter"></i> Social</a>
                                <a href="#"><i class="feather-image"></i> Gallery</a>
                                <a href="#"><i class="feather-dollar-sign"></i> Pricing</a>
                                <a href="#"><i class="feather-percent"></i> Progressbar</a>
                                <a href="#"><i class="feather-message-square"></i> Testimonial</a>
                                <a href="#"><i class="feather-layout"></i> Service</a>
                                <a href="#"><i class="feather-map"></i> Split Area</a>
                                <a href="#"><i class="feather-search"></i> Search Style</a>
                                <a href="#"><i class="feather-instagram"></i> Instagram Style</a>
                                <a href="#"><i class="feather-list"></i> List Style</a>
                                <a href="#"><i class="feather-airplay"></i> Creative Tab</a>
                            </div>
                        </div>
                        <!-- End Single Testimonial  -->
                    </div>
                </div>

                <div class="scroll-animation-wrapper mt--30">
                    <div class="scroll-animation scroll-left-right">
                        <!-- Start Single Testimonial  -->
                        <div class="single-column-100">
                            <div class="rbt-categori-list">
                                <a href="#"><i class="feather-type"></i> Heading</a>
                                <a href="#"><i class="feather-align-center"></i> Section Title</a>
                                <a href="#"><i class="feather-arrow-right-circle"></i> Slider</a>
                                <a href="#"><i class="feather-rotate-ccw"></i> Flip Box</a>
                                <a href="#"><i class="feather-camera"></i> Fancy Image</a>
                                <a href="#"><i class="feather-chrome"></i> Group Image</a>
                                <a href="#"><i class="feather-circle"></i> Circle Badge</a>
                                <a href="#"><i class="feather-toggle-left"></i> Creative Badge</a>
                                <a href="#"><i class="feather-link"></i> Animated Button</a>
                                <a href="#"><i class="feather-globe"></i> Language Switcher</a>
                                <a href="#"><i class="feather-gift"></i> Offer Banner</a>
                                <a href="#"><i class="feather-video"></i> Video Popup</a>
                                <a href="#"><i class="feather-clock"></i> Timeline</a>
                                <a href="#"><i class="feather-code"></i> Carousel Testimonial</a>
                                <a href="#"><i class="feather-award"></i> Event Item</a>
                                <a href="#"><i class="feather-user-check"></i> Team Member</a>
                                <a href="#"><i class="feather-star"></i> Review</a>
                                <a href="#"><i class="feather-voicemail"></i> Subscribe</a>
                                <a href="#"><i class="feather-jash"></i> Counter</a>
                                <a href="#"><i class="feather-skip-back"></i> Go to Top</a>
                                <a href="#"><i class="feather-filter"></i> Isotope Filter</a>
                                <a href="#"><i class="feather-bookmark"></i> Bookmark</a>
                            </div>
                        </div>
                        <!-- End Single Testimonial  -->
                    </div>
                </div>

                <div class="scroll-animation-wrapper mt--30">
                    <div class="scroll-animation scroll-right-left">
                        <!-- Start Single Testimonial  -->
                        <div class="single-column-100">
                            <div class="rbt-categori-list">
                                <a href="#"><i class="feather-bold"></i> Animated Big Text</a>
                                <a href="#"><i class="feather-user-plus"></i> Instructor Group</a>
                                <a href="#"><i class="feather-heart"></i> Offer Badge</a>
                                <a href="#"><i class="feather-file-text"></i> Exam Preparation</a>
                                <a href="#"><i class="feather-box"></i> Modal</a>
                                <a href="#"><i class="feather-copy"></i> Pricing Switcher</a>
                                <a href="#"><i class="feather-database"></i> Step Box</a>
                                <a href="#"><i class="feather-credit-card"></i> Tuition & Fees Table</a>
                                <a href="#"><i class="feather-bar-chart"></i> Single Pricing</a>
                                <a href="#"><i class="feather-more-horizontal"></i> Contact From</a>
                                <a href="#"><i class="feather-watch"></i>Countdown Timer</a>
                                <a href="#"><i class="feather-pie-chart"></i> Radial Progress</a>
                                <a href="#"><i class="feather-command"></i> Parallax</a>
                                <a href="#"><i class="feather-search"></i> Advance Search</a>
                                <a href="#"><i class="feather-tv"></i> Content Box</a>
                                <a href="#"><i class="feather-shopping-cart"></i> Minicart</a>
                                <a href="#"><i class="feather-sidebar"></i> Offcanvas</a>
                                <a href="#"><i class="feather-chevrons-right"></i> Image Carousel</a>
                                <a href="#"><i class="feather-italic"></i> Sliding Heading</a>
                                <a href="#"><i class="feather-paperclip"></i> Tag cloud</a>
                                <a href="#"><i class="feather-menu"></i> Hamburger</a>
                                <a href="#"><i class="feather-zap"></i> Advance Dropdown</a>
                                <a href="#"><i class="feather-skip-forward"></i> Pagination</a>
                            </div>
                        </div>
                        <!-- End Single Testimonial  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ElementIncluded',
}
</script>

<style scoped>
</style>