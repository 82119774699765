<template>
  <div class="globe-container">
    <div ref="globeContainer" class="globe"></div>
    <div class="info-panel left">
      <div class="info-item">Moneda Popular: BTC</div>
      <div class="info-item">Movimiento actual: 243123214</div>
      <div class="info-item">Nueva moneda: ETC</div>
    </div>
    <div class="info-panel right">
      <div class="info-item">Moneda Popular: BTC</div>
      <div class="info-item">Movimiento actual: 243123214</div>
      <div class="info-item">Nueva moneda: ETC</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import Globe from 'globe.gl';

export default defineComponent({
  setup() {
    const globeContainer = ref(null);
    let globe;

    onMounted(() => {
      globe = Globe()
        .globeImageUrl('//unpkg.com/three-globe/example/img/earth-dark.jpg')
        .backgroundColor('rgb(160, 160, 160)') // Fondo blanco
        .pointsData(generateRandomPoints(200))
        .pointAltitude('altitude')
        .pointColor(() => ['#3388ff', '#ff3388', '#88ff33'][Math.floor(Math.random() * 3)])
        .pointRadius(0.5)
        .arcsData(generateArcs(200, 50))
        .arcColor(() => '#ffffff')
        .arcDashLength(0.4)
        .arcDashGap(0.2)
        .arcDashAnimateTime(1500)
        .atmosphereColor('rgba(255,255,255,0.1)')
        .atmosphereAltitude(0.15);

      globe(globeContainer.value);

      // Optional: Add controls or interactivity
      globe.controls().autoRotate = true;
      globe.controls().autoRotateSpeed = 0.5;
    });

    function generateRandomPoints(n) {
      return Array.from({ length: n }, () => ({
        lat: (Math.random() - 0.5) * 180,
        lng: (Math.random() - 0.5) * 360,
        altitude: Math.random() * 0.1
      }));
    }

    function generateArcs(pointsCount, arcsCount) {
      const points = generateRandomPoints(pointsCount);
      return Array.from({ length: arcsCount }, () => {
        const startIndex = Math.floor(Math.random() * points.length);
        const endIndex = Math.floor(Math.random() * points.length);
        return {
          startLat: points[startIndex].lat,
          startLng: points[startIndex].lng,
          endLat: points[endIndex].lat,
          endLng: points[endIndex].lng,
        };
      });
    }

    return { globeContainer };
  }
});
</script>

<style scoped>
.globe-container {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: black;
}

.globe {
  width: 100%;
  height: 100%;
}

.info-panel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.left {
  left: 20px;
}

.right {
  right: 20px;
}

.info-item {
  margin-bottom: 10px;
}
</style>